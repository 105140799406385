
import { onMounted, reactive, ref, unref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  sendVcode,
  updateWebCommentator,
  validVcode1,
  getWxOpenId,
  judgeOpenId,
} from "@/api/web";
import { Toast } from "vant";
import { getUrlCode } from "@/utils/tool";

export default {
  setup() {
    const state = reactive({
      clientName: "",
      userName: "",
      mobile: "",
      validCode: "",
      tenantId: "",
      openId: "",
      unionId: "",
    });

    const toDeptList = () => {
      router.push({
        name: "deptList",
      });
    };

    const isSubmit = reactive({
      flag: true,
      codeText: "发送验证码",
    });

    const formRef = ref();
    const vcodeRef = ref();

    const handleSendVcode = async () => {
      store.commit('setToken', null);
      const form = unref(formRef);
      if (!form) return;
      if (!isSubmit.flag) {
        return;
      }
      try {
        await form.validate("mobile");
      } catch (err) {
        Toast({
          type: "fail",
          message: (err as Error).message,
        });
        return;
      }
      const params = { mobile: state.mobile.toString(), actionType: "1" };
      const res = await sendVcode(params as any);
      // 发送成功后调用倒计时
      if (res && (res as any).resp_code === 0) {
        vcodeRef.value.focus();
        Toast({
          type: "success",
          message: "发送成功",
        });
        intervalEvent();
      }
    };

    const intervalEvent = () => {
      isSubmit.flag = false;
      let count = 60;
      let time = setInterval(() => {
        count--;
        isSubmit.codeText = count + "s";
        if (count == 1) {
          clearInterval(time);
          isSubmit.flag = true;
          isSubmit.codeText = "发送验证码";
        }
      }, 1000);
    };

    const router = useRouter();
    const store = useStore();
    const onSubmit = async () => {
      let toast = Toast.loading({
        message: "提交中...",
        forbidClick: true,
        duration: 0,
      });
      const validParams = {
        deviceId: state.mobile,
        validCode: state.validCode,
      };
      const validRes = await validVcode1(validParams);
      toast.clear();
      if ((validRes as any).resp_code === 0) {
        // 发起绑定
        const IWebParams = {
          tenantId: state.tenantId,
          webCommentatorAccount: state.mobile,
          webCommentatorName: state.userName,
          openId: state.openId,
          unionId: state.unionId,
        };
        const webRes = await updateWebCommentator(IWebParams);
        if ((webRes as any).resp_code === 0) {
          Toast({
            type: "success",
            message: (webRes as any).resp_msg,
          });
        }
      }
    };

    // 初始化所属单位选择内容
    const init = () => {
      const selectItem = sessionStorage.getItem("selectItem");
      const parseSelectItem = JSON.parse(selectItem as any);
      if (parseSelectItem && parseSelectItem.menuId) {
        state.tenantId = parseSelectItem.menuId;
        state.clientName = parseSelectItem.menuName;
      }
    };

    // 通过code获取openid 和unionid
    const getOpenIdByCode = async () => {
      let code = getUrlCode("code");
      if(!code) {
        return
      }
      const openIdRes = await getWxOpenId(code);
      if ((openIdRes as any).resp_code === 0) {
        let data = (openIdRes as any).datas;
        // 注意返回的微信用户信息是json字符串
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        state.openId = data.openid;
        state.unionId = data.unionid;
        store.commit('setOpenId', data.openid);
        store.commit('setUnionId', data.unionid);
        let param = {
          openId: state.openId,
        };
        const res = await judgeOpenId(param as any);
        if ((res as any).resp_code === 0) {
          if ((res as any).datas === -1) {
            Toast({
              type: "fail",
              message: (res as any).resp_msg,
            });
            setTimeout(() => {
              router.replace({
                name: "Login",
              });
            }, 1000);
          }
        }
        return;
      }
      Toast({
        type: "fail",
        message: (openIdRes as any).resp_msg,
      });
    };

    const validFailed = (err: any) => {
      Toast({
        type: "fail",
        message: err.errors[0].message,
      });
    };

    const validMobile = (value: string) => {
      return !!value.match(/^1[3-9]\d{9}$/);
    };

    onMounted(() => {
      init();
      // 先获取openid 然后提交
      if (!store.state.openId) {
        getOpenIdByCode();
      } else {
        state.openId = store.state.openId;
        state.unionId = store.state.unionId;
      }
    });
    return {
      state,
      toDeptList,
      isSubmit,
      formRef,
      vcodeRef,
      handleSendVcode,
      intervalEvent,
      onSubmit,
      validFailed,
      validMobile,
    };
  },
};
